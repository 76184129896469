let canvas
let video

export function drawingLoop(c, v) {
    if(typeof c !== "number" && typeof v !== "number") {
        canvas = c
        video = v
    }

    // console.log(canvas, video)
    const ctx = canvas.getContext('2d')
    const vidWidth = video.videoWidth;
    const vidHeight = video.videoHeight;

    canvas.width = video.offsetWidth * (window.devicePixelRatio || 1);
    canvas.height = video.offsetHeight * (window.devicePixelRatio || 1);

    ctx.drawImage(video, 0, 0, vidWidth, vidHeight, 0, 0, canvas.width, canvas.height);

    window.requestAnimationFrame(drawingLoop)
}

export function setVideoBgColor(vid, backgroundElement) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 8;
    canvas.height = 8;

    ctx.drawImage(vid, 0, 0, 8, 8);

    const p = ctx.getImageData(0, 0, 8, 8).data;

    backgroundElement.style.backgroundColor = "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
}