import React, {useRef} from "react"
import {Link} from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import {drawingLoop, setVideoBgColor} from "../utils/MakeVideoToCanvas";
import {MENU} from "../config";
// import Highlighted from "../components/Highlighted";

// Assets
// import MamaIndia from '../images/projects/mama_india.jpg'
// import Predicat from '../images/projects/predicat.jpg'
// import Mockup from '../images/projects/mockup.jpg'
import video from '../video/background.mp4'
import videoWebm from '../video/background.webm'


const IndexPage = () => {

    const vid = useRef()
    const canvas = useRef()

    function HandleLoadedData (event) {
        setVideoBgColor(event.currentTarget, document.body)
        drawingLoop(canvas.current, event.currentTarget)
    }

    return (
        <Layout>
            <SEO title="Home" />
            <section className={"home container"}>
                <video ref={vid} className="home__visual" onLoadedData={HandleLoadedData} style={{position: "absolute", opacity: 0, zIndex: "-1"}} controls={false} autoPlay={true} loop muted crossOrigin="anonymous">
                    <source src={videoWebm} type="video/webm" />
                    <source src={video} type="video/mp4" />
                </video>
                <canvas ref={canvas} className="home__visual" id="canvas" />
                <div className={"home__headline"}>
                    <small>Predicat</small>
                    <p>
                        We are a collective that works in digital and print. <br />
                        We build your brand with you!<br />
                    </p>
                    <Link to={MENU.contact.link} className="link">Contact us! ></Link>
                </div>

            </section>

            {/*<Highlighted slides={[MamaIndia, Mockup, Predicat]} />*/}
        </Layout>
    )
}

export default IndexPage
